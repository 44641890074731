<template>
  <div>
    <strong>Conforming Item Upload</strong>

    <br>

    <div class="form-group">
      <label>Item</label>
      <Typeahead ref="curbFileHandlerCurb" :disabled="state.reference2ID > 0" cdi_type="curb" v-bind:ID.sync="state.referenceID" />
    </div>
    <div class="form-group">
      <label>Type</label>
      <select class="form-control" v-model="state.fileTypeID">
        <option :value="$cdiVars.FILE_TYPE_THREED_DRWNG">
          3d Drawing
        </option>
        <option :value="$cdiVars.FILE_TYPE_ENGINEERING_FL">
          Engineering
        </option>
        <option :value="$cdiVars.FILE_TYPE_WELDING_FL">
          Welding File
        </option>
        <option :value="$cdiVars.FILE_TYPE_KIT_CURB_BRAKE_FL">
          Kit Curb Brake
        </option>
        <option :value="$cdiVars.FILE_TYPE_ASSEM_PARTLABEL_FL">
          Assembly Part Label
        </option>
        <option :value="$cdiVars.FILE_TYPE_FIELD_ASSEM_DRWNG">
          Field Assembly
        </option>
        <option :value="$cdiVars.FILE_TYPE_PRODUCTION_NOTES">
          Production Notes
        </option>
        <option :value="$cdiVars.FILE_TYPE_E_DRAWING">
          E-Drawing
        </option>
        <option :value="$cdiVars.FILE_TYPE_CONFORMING_STAMP">
          Conforming Stamp
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>Conforming Stamp Location</label>
      <select class="form-control" v-model="conformingLocationID" @change="get_conforming_stamp_types()">
        <option v-for="[id, type] in conformingStampLocations" v-bind:key="id" :value="id">
          {{ type }}
        </option>
      </select>
    </div>
    <div class="form-group" v-show="conformingLocationID">
      <label>Code Year</label>
      <select class="form-control" v-model="state.conformingTypeID">
        <option v-for="[id, year] in conformingStampCodeYears" v-bind:key="id" :value="id">
          {{ year }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { fileUploadStore } from "@/store/FileUpload.store";
import { store } from "@/store/BusinessObject.store";
import conforming from '@/cache/conforming_stamp.cache.js';
import Typeahead from "@/components/utils/typeaheads/Typeahead";

export default {
  name: "CurbConforming",
  components: {
    Typeahead
  },
  data() {
    return {
      state: fileUploadStore.state,
      nomenclature: this.$appStore.state.fileUploadContextData.data.nomenclature,
      curbState: store.state,
      assignConformingStamp: false,
      conformingLocationID: '',
      conformingStampCodeYears: [
        [0, 'Options Not Available']
      ],
      conformingStampLocations: [],
      cache: {
        conforming,
      },
    }
  },
  created() {
    fileUploadStore.initialize(this.$cdiVars.FILE_TYPE_THREED_DRWNG);
    this.get_conforming_stamp_locations();
  },
  mounted() {
    this.$refs.curbFileHandlerCurb.$data.object = this.$appStore.state.fileUploadContextData.data.nomenclature;
    let upload_context = this.curbState.currentView
    this.$appStore.setFileUploadCallback(function () {
      store.load(store.state.object.id, upload_context);
    });
  },
  computed: {
    context: function() {
      return this.$appStore.state.fileUploadContext;
    }
  },
  watch: {
    context(to) {
      if( to === 'curb_conforming') {
        let upload_context = this.curbState.currentView
        this.$appStore.setFileUploadCallback(function () {
          store.load(store.state.object.id, upload_context);
        });
        this.state.referenceID = store.state.object.id;
      }
    }
  },
  methods: {
    get_conforming_stamp_types: function() {
      let options = {
        'params[conforming_location_id]': this.conformingLocationID
      }
      store.customApi('menu_builder/conforming_stamp_code_years', options).then(res => this.conformingStampCodeYears = res);
    },
    get_conforming_stamp_locations: function () {
      store.customApi('menu_builder/conforming_stamp_locations').then(
          res => {
            this.conformingStampLocations = res;
          }
      )
    }
  }
}
</script>

<style scoped>

</style>